<template>
<div class='managerNav'>
	<AppNav>
		<template slot='leftNav'>			
			<router-link tag='div' :to='{ name: "teams" }' class='appNavButton'>
				<svg class='smallIcon'>
					<use xlink:href='#team' />
				</svg>
				<span class='label'>{{ $t('elements.nav.teams') }}</span>
			</router-link>
			<router-link tag='div' :to='{ name: "staff" }' class='appNavButton staffButton'>
				<svg class='smallIcon'>
					<use xlink:href='#swap' />
				</svg>
				<span class='label'>Users</span>
			</router-link>			
			<router-link tag='div' :to='{ name: "uam" }' class='appNavButton'>
				<svg class='smallIcon'>
					<use xlink:href='#outcomes' />
				</svg>
				<span class='label'>User Logging</span>
			</router-link>
			<router-link tag='div' :to='{ name: "links" }' class='appNavButton'>
				<svg class='smallIcon'>
					<use xlink:href='#link' />
				</svg>
				<span class='label'>Links</span>
			</router-link>
		</template>
		<template slot='rightNav'>
			<div v-if='$store.state.profile.organization.closed' class='textButton tagAlert' @click='showMoreSeats'>
				<span class='label'>No subscription &mdash; Clinicians cannot login</span>
				<span class='more'>Sign up now!</span>
			</div>			
			<div v-else-if='$store.state.profile.organization.trialDaysLeft' class='textButton tagAcc' @click='showMoreSeats'>
				<span class='label'>Days left in trial: {{$store.state.profile.organization.trialDaysLeft}}</span>
				<span class='more'>Ready to Subscribe?</span>
			</div>	
			<div v-else-if="$store.state.profile.organization.seats!==null" class='textButton tagSuccess' @click='showMoreSeats'>
				<span class='label'>Seats in Use: {{$store.state.profile.organization.seats-$store.state.profile.organization.teamInvitations}} of {{$store.state.profile.organization.seats}}</span>
				<span class='more'>Need more?</span>
			</div>	
			<div v-if='$store.state.profile.user.clinician && !$store.state.profile.user.closed && !$store.state.profile.organization.closed' tag='div' class='toClinicianPortal appNavButton' @click='toClinicianView'>
				<svg class='smallIcon'>
					<use xlink:href='#designer' />
				</svg>
				<span class='label'>To Clinician Portal...</span>
			</div>			
		</template>
	</AppNav>
	<Flash />
</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import AppNav from '@/components/common/AppNav'
import Flash from '@/components/common/Flash'
import AddSeats from './teams/AddSeats'

export default {
	name: 'ManagerNav',
	mixins: [smallModalMixin],
	components: {
		AppNav,
		Flash
	},
	methods: {
		showMoreSeats() {
			this.showSmallModal(AddSeats)
		},
		toClinicianView() {
			this.$store.commit('exerciseBrowser/reset')
			this.$store.commit('setBuilder/reset')
			this.$store.commit('elements/reset')
			this.$store.commit('sets/reset')
			this.$store.commit('manageClients/reset')
			this.$store.commit('manageClientSets/reset')
			this.$store.commit('manageClientOutcomes/reset')
			this.$store.commit('saveClients/reset')
			this.$store.commit('saveClientSets/reset')
			this.$store.commit('clients/reset')
			this.$store.commit('manageTemplates/reset')
			this.$store.commit('saveTemplates/reset')
			this.$store.commit('manageEducation/reset')
			this.$store.commit('attachEducation/reset')
			this.$store.commit('manageOutcomes/reset')
			this.$router.push({ name: 'designer' })
		}
	}
}
</script>

<style lang='scss'>
.managerNav {
	.staffButton svg { width: 20px; }
	.rightPanel {
		margin-left: 0;
		flex: 1;
	}
	.textButton {
		white-space: nowrap; 
		margin: 0 auto;
		.more { 
			margin-left: $size-gutter * 2;
			font-weight: bold;
			color: $color-primary-accent;
			text-decoration: underline;
		}
	}
	.toClinicianPortal { margin-left: auto; }
	.textButton + .toClinicianPortal { margin-left: 0; }
}
</style>